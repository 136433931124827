import type {MDXProviderComponentsProp} from '@mdx-js/react'
import {MDXProvider} from '@mdx-js/react'
import {SkipNavLink} from '@reach/skip-nav'
import {useStaticQuery, graphql} from 'gatsby'
import type {FC} from 'react'
import React from 'react'
import styled, {ThemeProvider} from 'styled-components'

import {TelescopeProvider, __dummyTelescopeProviderTranslations, theme} from '@pleo-io/telescope'
import {GlobalStyle} from '@pleo-io/telescope-global-style'

import favicon from '@/assets/favicon.png'
import {Navigation} from '@/components/navigation'
import type {LayoutQuery} from '@/graphql-types'
import {CustomColorSchemeTokensGlobalStyle, minWidthDesktop} from '@/tokens'

import 'focus-visible'

import '@reach/skip-nav/styles.css'

import {GlobalDocsStyles} from './global-styles'
import Main from './main'
import {TelescopeMDXComponents} from './mdx-components'
import type {Data, PageContext} from './types'

import {Helmet} from '../../utilities/helmet'
import {FeatureTogglerModal, FeatureTogglerProvider} from '../feature-toggler/feature-toggler'
import {navWidth} from '../navigation/navigation.styles'
import {ThemeSwitcherProvider} from '../theme-switcher/theme-switcher'

const PageWrapper = styled.div`
    @media (min-width: ${minWidthDesktop}) {
        padding-left: ${navWidth}px;
    }
`

const Providers = ({
    children,
    components
}: {
    children: React.ReactNode
    components: MDXProviderComponentsProp
}) => (
    <TelescopeProvider translations={__dummyTelescopeProviderTranslations}>
        <FeatureTogglerProvider>
            <ThemeSwitcherProvider>
                <ThemeProvider theme={theme}>
                    <MDXProvider components={components}>{children}</MDXProvider>
                </ThemeProvider>
            </ThemeSwitcherProvider>
        </FeatureTogglerProvider>
    </TelescopeProvider>
)

export const Layout: FC<{
    is404Page?: boolean
    children: React.ReactNode
    pageContext: PageContext
    components?: MDXProviderComponentsProp
    location: Location
    path: string
    data: Data
}> = ({
    is404Page,
    children,
    pageContext,
    location,
    path,
    data,
    components = TelescopeMDXComponents
}) => {
    const tabs = pageContext?.frontmatter?.tabs || []
    const title = pageContext?.frontmatter?.title || ''
    const intro = pageContext?.frontmatter?.intro || ''
    const layoutMode = pageContext?.frontmatter?.layoutMode || ''
    const status = pageContext?.frontmatter?.status || ''
    const githubUrl = pageContext?.frontmatter?.githubUrl
    const storybookUrl = pageContext?.frontmatter?.storybookUrl
    const figmaUrl = pageContext?.frontmatter?.figmaUrl
    const editPageUrl = pageContext?.frontmatter?.editPageUrl

    const {
        site: {pathPrefix}
    } = useStaticQuery<LayoutQuery>(graphql`
        query Layout {
            site {
                pathPrefix
            }
        }
    `)

    const slug = pathPrefix ? location.pathname.replace(pathPrefix, '') : location.pathname

    return (
        <>
            <Helmet>
                <title>Telescope Design System</title>
                <link rel="icon" href={favicon} type="image/x-icon" />
                <html lang="en-GB" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <GlobalStyle />
            <CustomColorSchemeTokensGlobalStyle />
            <GlobalDocsStyles />
            <Providers components={components}>
                <FeatureTogglerModal />
                <PageWrapper>
                    <SkipNavLink />
                    <Navigation currentPath={path} />
                    <Main
                        pageContext={pageContext}
                        status={status}
                        data={data}
                        path={path}
                        is404Page={is404Page}
                        layoutMode={layoutMode}
                        title={title}
                        intro={intro}
                        githubUrl={githubUrl}
                        storybookUrl={storybookUrl}
                        figmaUrl={figmaUrl}
                        tabs={tabs}
                        slug={slug}
                        children={children}
                        editPageUrl={editPageUrl}
                    />
                </PageWrapper>
            </Providers>
        </>
    )
}

export default Layout
