import React from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'
import {Eye} from '@pleo-io/telescope-icons'

export const Wrapper = styled(Text)`
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${tokens.spacing18} ${tokens.spacing36};
    color: ${tokens.colorContentWarning};
    text-align: center;
    background: ${tokens.colorBackgroundWarningQuiet};
    border-top: ${tokens.borderWarning};
`

const DraftBanner = () => (
    <Wrapper>
        <Eye mr={6} /> <strong>This page is a draft</strong>. Its content is likely to change.
    </Wrapper>
)

export default DraftBanner
